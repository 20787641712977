<template>
  <div>
      <table
        v-if="tariff"
        role="table"
        class="table"
      >
        <tbody role="rowgroup">
          <tr role="row">
            <td
              class="col-sm-12 text-center"
              colspan="3"
            >
              ECOR
            </td>
          </tr>
          <tr role="row">
            <td
              class="col-sm-12 text-center"
              colspan="3"
            >
              ELECTRICITY BILL
            </td>
          </tr>
          <tr
            role="row"
            class="mytableborder"
          >
            <td
              role="cell"
              class="col-sm-6"
            >
              BILL NO
            </td>
            <td>:</td>
            <td
              data-label="Id"
              role="cell"
            >
              {{ tariff['id'] }}
            </td>
          </tr>
          <tr>
            <td role="cell">
              CODE
            </td>
            <td>:</td>
            <td
              data-label="Date"
              role="cell"
            >
              {{ tariff['code'] }}
            </td>
          </tr>
          <tr>
            <td role="cell">
              DATE
            </td>
            <td>:</td>
            <td
              data-label="Date"
              role="cell"
            >
              {{ tariff['date'] }}
            </td>
          </tr>
          <tr>
            <td role="cell">
              TIME
            </td>
            <td>:</td>
            <td
              data-label="Time"
              role="cell"
            >
              {{ tariff['time'] }}
            </td>
          </tr>
          <tr>
            <td role="cell">
              BILL BASIS
            </td>
            <td>:</td>
            <td
              data-label="Time"
              role="cell"
            >
              {{ tariff['type'] }}
            </td>
          </tr>

          <tr>
            <td role="cell">
              NAME
            </td>
            <td>:</td>
            <td
              data-label="User"
              role="cell"
            >
              {{ tariff['details']['user']['name'] }}
            </td>
          </tr>

          <tr>
            <td role="cell">
              QTR NO
            </td>
            <td>:</td>
            <td
              data-label="User"
              role="cell"
            >
              {{ tariff['details']['user']['quarter'] }}
            </td>
          </tr>

          <tr>
            <td role="cell">
              EMP NO
            </td>
            <td>:</td>
            <td
              data-label="emp_num"
              role="cell"
            >
              {{ tariff['details']['user']['emp_num'] }}
            </td>
          </tr>

          <tr>
            <td role="cell">
              DESGINATION
            </td>
            <td>:</td>
            <td
              data-label="DESGINATION"
              role="cell"
            >
              {{ tariff['details']['user']['designation'] }}
            </td>
          </tr>

          <tr>
            <td role="cell">
              DEPARTMENT
            </td>
            <td>:</td>
            <td
              data-label="User"
              role="cell"
            >
              {{ tariff['details']['user']['department'] }}
            </td>
          </tr>

          <tr>
            <td role="cell">
              CATEGORY
            </td>
            <td>:</td>
            <td
              data-label="Type"
              role="cell"
            >
              {{ tariff['details']['category']['name'] }}
            </td>
          </tr>

          <tr v-if="tariff['meters'].length > 0">
            <td>
              PREV DATE
            </td>
            <td>:</td>
            <td role="cell">
              {{ tariff['meters'][0]['prev_date'] }}
            </td>
          </tr>

          <tr v-if="tariff['meters'].length > 0">
            <td>
              METER 1 NO
            </td>
            <td>:</td>
            <td
              data-label="User"
              role="cell"
            >
              {{ tariff['meters'][0]['mnum'] }}
            </td>
          </tr>

          <!-- <tr v-if="tariff['meters'].length > 0">
            <td>
              M1 STATUS
            </td>
            <td>:</td>
            <td
              data-label="User"
              role="cell"
            >
              {{ tariff['meters'][0]['pres_status'] ? 'Active' : 'Faulty' }}
            </td>
          </tr> -->

          <tr v-if="tariff['meters'].length > 0">
            <td>
              M1 PREV READ
            </td>
            <td>:</td>
            <td role="cell">
              {{ tariff['meters'][0]['prev_read'] }}
            </td>
          </tr>

          <tr v-if="tariff['meters'].length > 0">
            <td>
              M1 PRES READ
            </td>
            <td>:</td>
            <td role="cell">
              {{ tariff['meters'][0]['pres_read'] }}
            </td>
          </tr>

          <tr v-if="tariff['meters'].length > 1">
            <td>
              METER 2 NO
            </td>
            <td>:</td>
            <td
              data-label="User"
              role="cell"
            >
              {{ tariff['meters'][1]['mnum'] }}
            </td>
          </tr>

          <!-- <tr v-if="tariff['meters'].length > 1">
            <td>
              M2 STATUS
            </td>
            <td>:</td>
            <td
              data-label="User"
              role="cell"
            >
              {{ tariff['meters'][1]['pres_status'] ? 'Active' : 'Faulty' }}
            </td>
          </tr> -->

          <tr v-if="tariff['meters'].length > 1">
            <td>
              M2 PREV READ
            </td>
            <td>:</td>
            <td role="cell">
              {{ tariff['meters'][1]['prev_read'] }}
            </td>
          </tr>

          <tr v-if="tariff['meters'].length > 1">
            <td>
              M2 PRES READ
            </td>
            <td>:</td>
            <td role="cell">
              {{ tariff['meters'][1]['pres_read'] }}
            </td>
          </tr>

          <tr>
            <td role="cell">
              TOTAL UNITS
            </td>
            <td>:</td>
            <td
              data-label="Amount"
              role="cell"
            >
              {{ tariff.total_units }}
            </td>
          </tr>

          <tr>
            <td role="cell">
              MFC
            </td>
            <td>:</td>
            <td
              data-label="mfc"
              role="cell"
            >
              {{ tariff['details']['category']['mfc'] }}
            </td>
          </tr>

          <tr>
            <td role="cell">
              ENERGY CHARGES
            </td>
            <td>:</td>
            <td
              data-label="User Fare"
              role="cell"
            />
          </tr>

          <tr
            v-for="(val, index) in tariff['details']['energy_charges']"
            :key="index"
          >
            <td class="text-right">
              {{ val.difference }} x {{ val.price }}
            </td><td>=</td><td>{{ val.subtotal }}</td>
          </tr>

          <tr class="mytableborder">
            <td role="cell">
              EC AMOUNT
            </td>
            <td>:</td>
            <td
              data-label="Total"
              role="cell"
            >
              {{ tariff['total_with_mfc'] }}
            </td>
          </tr>

          <tr>
            <td role="cell">
              ED (4%)
            </td>
            <td>:</td>
            <td
              data-label="Ed Amt"
              role="cell"
            >
              {{ tariff['ed_charges'] }}
            </td>
          </tr>
          <tr>
            <td role="cell">
              TOTAL AMOUNT
            </td>
            <td>:</td>
            <td
              data-label="Amount"
              role="cell"
            >
              {{ tariff.final_amount }}
            </td>
          </tr>
          <tr class="mytableborder">
            <td
              colspan="3"
              class="text-center"
            >
              Thank You
            </td>
          </tr>
        </tbody>
      </table>

      <b-button
        class="mr-4 float-left"
        variant="warning"
        size="sm"
        to="/tariffs"
      >
        <i class="fa fa-arrow-circle-left" /> Back
      </b-button>
      <b-button
        class="mr-5 float-left"
        variant="danger"
        size="sm"
        @click="deleteBill"
      >
        <i class="fa fa-close" /> Delete
      </b-button>
      <b-button
        class="float-right"
        variant="primary"
        size="md"
        @click="print"
      >
        <i class="fa fa-print" /> Print
      </b-button>
  </div>
</template>

<script>
import { apiService } from '@/services'
import { mapActions } from 'vuex'
import router from '@/router'

export default {
  name: 'ShowTariff',
  data () {
    return {
      isBusy: true,
      tariff: ''
    }
  },
  mounted () {
    this.getTariff(this.$route.params.id)
  },
  methods: {
    ...mapActions({ showError: 'error/show', deleteItem: 'tariff/deleteItem' }),
    getTariff (id) {
      apiService.getAll(`/tariffs/${id}`).then((response) => {
        this.isBusy = false
        this.tariff = response.data
      }).catch(error => {
        this.showError(error)
        window.close()
      })
    },
    print () {
      const title = document.title
      document.title = title + this.tariff.id
      window.print()
      document.title = title
    },
    deleteBill () {
      const res = window.confirm('Delete Bill?')
      if (res) {
        this.deleteItem(this.tariff.id)
        router.go(-1)
      }
    }
  }
}
</script>

<style scoped>
.mytableborder{ border-top: 2px solid black; }
td:first-child { text-align: left; font-weight: 500; width: 50%; text-transform: uppercase; }
td:last-child { font-weight: bold; word-break: break-all; }
table { width: 350px; font-size: 14px; font-family: Arial, Helvetica, sans-serif; border: solid #000 !important; border-width: 1px 1px 1px 1px !important; margin-bottom: 40px;}
.alertz{ z-index: 2000; }
@media print {
    .mytableborder{ border-top: 2px solid black; }
    td:first-child { text-align: left; font-weight: 500; width: 50%; text-transform: uppercase; }
    td:last-child { font-weight: bold; word-break: break-all; }
    button{ display: none; }
    .alert{ display: none; }
    table { width: 600px; font-size: 38px; font-family: Arial, Helvetica, sans-serif; border: solid #000 !important; border-width: 1px 1px 1px 1px !important; margin-bottom: 2px; }
    table tr:first-child { font-size: 50px; }
}
</style>
